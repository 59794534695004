<template>
    <div class="app-container">
        <div class="app-top-btn">
            <el-button type="primary" size="medium" @click="listAdd">
                新增
            </el-button>
        </div>
        <el-table :data="list.data" border fit highlight-current-row>
            <el-table-column
                align="center"
                label="标题"
                min-width="280"
                prop="title"
            >
            </el-table-column>
            <el-table-column
                align="center"
                label="内容"
                min-width="280"
                prop="content"
            >
            </el-table-column>
            <!-- <el-table-column
                align="center"
                label="消息类型"
                min-width="120"
                prop="messageType"
            >
            </el-table-column>
            <el-table-column
                align="center"
                label="消息链接"
                min-width="120"
                prop="messageUrl"
            >
            </el-table-column> -->
            <el-table-column
                align="center"
                label="创建时间"
                min-width="120"
                prop="createTime"
            >
            </el-table-column>
            <el-table-column
                align="center"
                label="操作"
                fixed="right"
                min-width="160"
            >
                <template slot-scope="scope">
                    <el-button
                        type="info"
                        size="mini"
                        @click="listEdit(scope.row)"
                    >
                        编辑
                    </el-button>
                    <el-button
                        type="primary"
                        size="mini"
                        @click="showSendDialog(scope.row)"
                    >
                        发送
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--分页-->
        <div class="app-pagination">
            <el-pagination
                background
                layout="total, prev, pager, next"
                :total="listTotal"
                :page-size="listQuery.pageSize"
                :current-page="listQuery.pageNum"
                @current-change="pageNumChange"
            >
            </el-pagination>
        </div>
        <!-- 新增/编辑 -->
        <el-dialog
            :title="list.form.title"
            :visible.sync="list.form.show"
            width="800px"
            :modal-append-to-body="false"
            :close-on-click-modal="false"
        >
            <el-form
                :model="list.form.fields"
                :rules="list.form.rules"
                :ref="list.form.ref"
            >
                <el-form-item label="标题" prop="title">
                    <el-input v-model="list.form.fields.title"></el-input>
                </el-form-item>
                <el-form-item label="内容" prop="content">
                    <el-input
                        type="textarea"
                        v-model="list.form.fields.content"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="listSubmit">
                        提交
                    </el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- 发送消息 -->
        <el-dialog
            :title="sendinfo.title"
            :visible.sync="sendinfo.show"
            width="800px"
            :modal-append-to-body="false"
            :close-on-click-modal="false"
        >
            <el-form
                :model="sendinfo.fields"
                :rules="sendinfo.rules"
                :ref="sendinfo.ref"
            >
                <el-form-item label="标题" prop="type">
                    <el-radio-group v-model="sendinfo.fields.type">
                        <el-radio :label="0">所有人</el-radio>
                        <el-radio :label="1">部分人</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item
                    v-if="sendinfo.fields.type && sendinfo.fields.type === 1"
                    label="包含人员"
                    prop="userIdList"
                >
                    <el-transfer
                        :titles="['可选列表', '已选列表']"
                        v-model="sendinfo.fields.userIdList"
                        filterable
                        :props="{
                            key: 'id',
                            label: 'userName',
                        }"
                        :data="userlist"
                    >
                    </el-transfer>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="sendMessage">
                        提交
                    </el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            userlist: [],
            list: {
                data: [],
                form: {
                    ref: 'listForm',
                    title: '新增',
                    show: false,
                    fields: {},
                    rules: {
                        title: [
                            {
                                required: true,
                                message: '必填',
                                trigger: 'blur',
                            },
                        ],
                    },
                },
            },
            listTotal: 0,
            listQuery: {
                pageNum: 1,
                pageSize: 10,
                keyWords: '',
            },
            sendinfo: {
                show: false,
                title: '发送',
                ref: 'sendForm',
                fields: {
                    id: '',
                    type: 0,
                    userIdList: [],
                },
                rules: {
                    type: [
                        {
                            required: true,
                            message: '必填',
                            trigger: 'blur',
                        },
                    ],
                },
            },
            messageTypeList: [],
        }
    },
    created() {
        this.getList()
        this.getUserList()
    },
    methods: {
        // 分页处理
        pageNumChange(page) {
            if (page != this.listQuery.pageNum) {
                this.listQuery.pageNum = page
                this.getList()
            }
        },
        // 获取列表
        getList() {
            this.$api.message_page(this.listQuery).then((res) => {
                this.listTotal = res.pageInfo.total
                this.list.data = res.data
            })
        },
        getUserList() {
            this.$api.user_list().then((res) => {
                this.userlist = res
            })
        },
        // sendMessage
        showSendDialog(row) {
            this.sendinfo.fields.id = row.id
            this.sendinfo.show = true
        },
        sendMessage() {
            // console.log('sendMessage', this.sendinfo.form.fields)
            this.$api
                .message_send(this.sendinfo.fields)
                .then(() => {
                    this.$message({
                        duration: 1000,
                        type: 'success',
                        message: '发送成功',
                    })
                    this.sendinfo.show = false
                })
                .catch(() => {
                    this.$message({
                        duration: 1000,
                        type: 'error',
                        message: '发送失败',
                    })
                    this.sendinfo.show = false
                })
        },
        listAdd() {
            this.list.form.title = '新增'
            this.list.form.fields = {
                content: '',
                messageType: 0,
                messageUrl: null,
                title: '',
            }
            this.list.form.show = true
        },
        listEdit(row) {
            this.list.form.fields = row
            this.list.form.title = '编辑'
            this.list.form.show = true
        },
        listSubmit() {
            this.$refs[this.list.form.ref].validate((valid) => {
                if (valid) {
                    if (this.list.form.fields.id) {
                        this.$api
                            .message_update(this.list.form.fields)
                            .then(() => {
                                this.$message({
                                    duration: 1000,
                                    type: 'success',
                                    message: '修改成功',
                                })
                                this.list.form.show = false
                                this.getList()
                            })
                    } else {
                        this.$api
                            .message_save(this.list.form.fields)
                            .then(() => {
                                this.$message({
                                    duration: 1000,
                                    type: 'success',
                                    mmessage: '新增成功',
                                })
                                this.list.form.show = false
                                this.getList()
                            })
                    }
                } else {
                    console.log('error submit!!')
                    this.$message.warning('内容有误，请检查')
                    return false
                }
            })
        },
        resetForm() {
            this.$refs[this.list.form.ref].resetFields()
        },
    },
}
</script>
